import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Toaster } from "./components/toaster";
import { settings } from "./utils/constants";
import { AuthConsumer, AuthProvider } from "./contexts/auth-context";
import { useNprogress } from "./hooks/use-nprogress";
import { routes } from "./routes";
import { createTheme } from "./theme";
import { OrganizationProvider } from "./contexts/organization-context";
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "simplebar-react/dist/simplebar.min.css";
import { Worker } from "@react-pdf-viewer/core";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
export const App = () => {
  useNprogress();

  const element = useRoutes(routes);

  const theme = createTheme({
    colorPreset: settings.colorPreset,
    contrast: settings.contrast,
    direction: settings.direction,
    paletteMode: settings.paletteMode,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <AuthConsumer>
            {(authContext) => (
              <OrganizationProvider>
                <ThemeProvider theme={theme}>
                  <Helmet>
                    <meta name="color-scheme" content={settings.paletteMode} />
                    <meta
                      name="theme-color"
                      content={theme.palette.neutral[900]}
                    />
                  </Helmet>
                  <CssBaseline />
                  <>{element}</>
                  <Toaster />
                </ThemeProvider>
              </OrganizationProvider>
            )}
          </AuthConsumer>
        </AuthProvider>
      </LocalizationProvider>
    </Worker>
  );
};
