import { useMemo } from "react";
import { SvgIcon } from "@mui/material";

import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";

export const useSections = () => {
  return useMemo(() => {
    return [
      {
        title: "Calendar",
        items: [],
      },
      {
        title: "Account",
        path: paths.dashboard.account,
        icon: (
          <SvgIcon fontSize="small">
            <HomeSmileIcon />
          </SvgIcon>
        ),
      },
    ];
  }, []);
};
