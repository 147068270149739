import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { Navigate } from "react-router-dom";
import { paths } from "src/paths";
import { element } from "prop-types";

const ProfileSettingsPage = lazy(() =>
  import("src/pages/dashboard/settings/profile-settings")
);
const WorkspaceSettingsPage = lazy(() =>
  import("src/pages/dashboard/settings/workspace-settings")
);

const TableView = lazy(() => import("src/pages/dashboard/agent"));
const EngageTableView = lazy(() =>
  import("src/pages/dashboard/engage-table-view")
);

const WorkspaceListPage = lazy(() =>
  import("src/pages/dashboard/settings/workspace-list")
);
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"));
const CalendarSettingsPage = lazy(() =>
  import("src/pages/dashboard/settings/calendar-settings")
);

const CreateAgentPage = lazy(() => import("src/pages/dashboard/create-agent"));
const CreateAgentForm = lazy(() =>
  import("src/pages/dashboard/create-agent-form")
);

const AnalyticsPage = lazy(() => import("src/pages/dashboard/analytics"));

const CampaignAnalyticsPage = lazy(() =>
  import("src/pages/dashboard/campaign-analytics")
);

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={paths.dashboard.calendar} />,
      },
      {
        path: "account",
        element: <ProfileSettingsPage />,
      },
      {
        path: "workspace",
        element: <WorkspaceSettingsPage />,
      },
      {
        path: "workspaces",
        element: <WorkspaceListPage />,
      },
      {
        path: "calendar-settings",
        element: <CalendarSettingsPage />,
      },
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "agents",
        element: <TableView />,
      },
      {
        path: "create-agent",
        element: <CreateAgentPage />,
      },
      {
        path: "create-agent/:type",
        element: <CreateAgentForm />,
      },
      {
        path: "engage",
        element: <EngageTableView />,
      },
      {
        path: "analytics",
        element: <AnalyticsPage />,
      },
      {
        path: "campaign-analytics/:campaign_uuid",
        element: <CampaignAnalyticsPage />,
      },
    ],
  },
];
