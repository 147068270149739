import { BASE_URL_DASHBOARD } from "../utils/constants";
import { BASE_URL_API } from "../utils/constants";

export const getCalendars = async (organizationUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/organization/calendars?organization_uuid=${organizationUUID}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.warn("Get calendars error:", error.message);
    throw error;
  }
};

export const updateAgentCalendarStatus = async (calendarUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar/status`;
    const response = await fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calendar_uuid: calendarUUID,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.warn("Update calendar status error:", error.message);
    throw error;
  }
};

export const addCalendar = async (formData) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar`;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to add calendar");
    }
  } catch (error) {
    console.warn("Add calendar error:", error.message);
    throw error;
  }
};

export const getCalendarDetails = async (calendarUUID, organizationUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar/users?calendar_uuid=${calendarUUID}&organization_uuid=${organizationUUID}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || "Failed to fetch calendar details");
    }
  } catch (error) {
    console.warn("Get calendar details error:", error.message);
    throw error;
  }
};

export const updateCalendarDetails = async (calendarUUID, formData) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar`;

    formData.append("calendar_uuid", calendarUUID);

    const response = await fetch(url, {
      method: "PUT",
      body: formData,
      credentials: "include",
    });

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getOrganizationUsers = async () => {
  try {
    const url = `${BASE_URL_DASHBOARD}/organization/users`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to fetch organization users");
    }
  } catch (error) {
    console.warn("Get organization users error:", error.message);
    throw error;
  }
};

export const addCalendarUser = async (userData) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar/user`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
      credentials: "include",
    });

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCalendarUsers = async (calendarUUID, organizationUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar/users?calendar_uuid=${calendarUUID}&organization_uuid=${organizationUUID}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to fetch calendar users");
    }
  } catch (error) {
    console.warn("Get calendar users error:", error.message);
    throw error;
  }
};

export const updateCalendarUserRole = async (
  calendarUUID,
  calendarUserUUID,
  role
) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar/user`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calendar_uuid: calendarUUID,
        calendar_user_uuid: calendarUserUUID,
        role: role,
      }),
      credentials: "include",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.warn("Update calendar user role error:", error.message);
    throw error;
  }
};

export const deleteCalendarUser = async (calendarUUID, calendarUserUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar/user`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calendar_uuid: calendarUUID,
        calendar_user_uuid: calendarUserUUID,
      }),
      credentials: "include",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.warn("Delete calendar user error:", error.message);
    throw error;
  }
};

export const deleteCalendar = async (calendarUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ calendar_uuid: calendarUUID }),
      credentials: "include",
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.warn("Leave calendar error:", error.message);
    throw error;
  }
};

export const connectCalendar = async (channel, calendar_uuid) => {
  try {
    const url = `${BASE_URL_API}/channels/connect_calendar`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ channel, calendar_uuid }),
      credentials: "include",
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.warn("Connect calendar error:", error.message);
    throw error;
  }
};

export const connectedCalendar = async (calendarUUID) => {
  try {
    const url = `${BASE_URL_API}/channels/connected_calendars`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ calendar_uuid: calendarUUID }),
      credentials: "include",
    });

    const data = await response.json();
    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to get connected calendars");
    }
  } catch (error) {
    console.warn("Get connected calendars error:", error.message);
    throw error;
  }
};

export const disconnectCalendar = async (
  channel,
  calendarUUID,
  calendar_channel_uuid
) => {
  try {
    const url = `${BASE_URL_API}/channels/disconnect_calendar`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calendar_uuid: calendarUUID,
        channel: channel,
        calendarChannelUUID: calendar_channel_uuid,
      }),
      credentials: "include",
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.warn("Disconnect calendar error:", error.message);
    throw error;
  }
};

export const leaveCalendar = async (calendarUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/calendar/leave`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calendar_uuid: calendarUUID,
      }),
      credentials: "include",
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.warn("Delete calendar user error:", error.message);
    throw error;
  }
};
